<template>
    <div class="content-wrapper cv-section">
        <h1>{{ $t('Edit Internship') }}</h1>

        <form @submit.prevent="save">
            
            <template v-for="(internship, index) in items" :key="index">
                <div :class="['labeled-icon-input', {'has-error': errors[`internships.${index}.job_title`]}]">
                
                    <div class="form-group">
                        <label :for="`job_title_${index}`">{{ $t('Job Title') }}</label>
                        <input v-model="items[index].job_title" class="form-control" type="text" :id="`job_title_${index}`">
                        <label :for="`job_title_${index}`" class="icon-label">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                <g>
                                    <g>
                                        <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                    </g>
                                </g>
                            </svg>
                        </label>
                    </div>

                    <FormErrors v-if="errors[`internships.${index}.job_title`]" :errors="errors[`internships.${index}.job_title`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`internships.${index}.employer`]}]">
                    
                    <div class="form-group">
                        <label :for="`employer_${index}`">{{ $t('Employer') }}</label>
                        <input v-model="items[index].employer" class="form-control" type="text" :id="`employer_${index}`">
                        <label :for="`employer_${index}`" class="icon-label">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                <g>
                                    <g>
                                        <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                    </g>
                                </g>
                            </svg>
                        </label>
                    </div>

                    <FormErrors v-if="errors[`internships.${index}.employer`]" :errors="errors[`internships.${index}.employer`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`internships.${index}.industry_id`]}]">
                    <div class="form-group">
                        <label>{{ $t('Industry') }}</label>
                        <Multiselect
                            v-model="items[index].industry_id"
                            :options="industries"
                            :placeholder="$t('Select indusrty')"
                            class="form-control"
                            :searchable="true"
                        />
                    </div>
                    
                    <FormErrors v-if="errors[`internships.${index}.industry_id`]" :errors="errors[`internships.${index}.industry_id`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`internships.${index}.date_from`]}]">
                    
                    <div class="form-group">
                        <label :for="`date_from_${index}`">{{ $t('Date From') }}</label>
                        <datepicker class="form-control"
                            @update:modelValue="items[index].date_from = moment($event).format('yyyy-MM-DD')"
                            :value="items[index].date_from"
                            :id="`date_from_${index}`"
                            :placeholder="$t('Select date from')"
                        />
                        <label :for="`date_from_${index}`" class="icon-label">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                <g>
                                    <g>
                                        <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                    </g>
                                </g>
                            </svg>
                        </label>
                    </div>

                    <FormErrors v-if="errors[`internships.${index}.date_from`]" :errors="errors[`internships.${index}.date_from`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`internships.${index}.date_to`]}]">
                    
                    <div class="form-group">
                        <label :for="`date_to_${index}`">{{ $t('Date To') }}</label>
                        <datepicker class="form-control"
                            @update:modelValue="items[index].date_to = moment($event).format('yyyy-MM-DD')"
                            :value="items[index].date_to"
                            :id="`date_to_${index}`"
                            :placeholder="$t('Select date to')"
                        />
                        <label :for="`date_to_${index}`" class="icon-label">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                <g>
                                    <g>
                                        <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                    </g>
                                </g>
                            </svg>
                        </label>
                    </div>

                    <FormErrors v-if="errors[`internships.${index}.date_to`]" :errors="errors[`internships.${index}.date_to`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`internships.${index}.country_id`]}]">
                    <div class="form-group">
                        <label>{{ $t('Country') }}</label>
                        <Multiselect
                            v-model="items[index].country_id"
                            :options="countries"
                            :placeholder="$t('Select country')"
                            class="form-control"
                            :searchable="true"
                        />
                    </div>
                    
                    <FormErrors v-if="errors[`internships.${index}.country_id`]" :errors="errors[`internships.${index}.country_id`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`internships.${index}.description`]}]">
                    
                    <div class="form-group textarea-form-group">
                        <textarea v-model="items[index].description" :placeholder="$t('Enter Description')" class="form-control"></textarea>
                    </div>

                    <FormErrors v-if="errors[`internships.${index}.description`]" :errors="errors[`internships.${index}.description`]" />

                </div>

                <div :class="['labeled-icon-input form-check', {'has-error': errors[`internships.${index}.is_current`]}]">
                    <div class="form-group">
                        <input class="form-check-input" type="checkbox" :id="`is_current_${index}`" v-model="items[index].is_current" :checked="items[index].is_current">
                        <label class="form-check-label" :for="`is_current_${index}`">{{ $t('Current internship') }}</label>
                    </div>
                    
                    <FormErrors v-if="errors[`internships.${index}.is_current`]" :errors="errors[`internships.${index}.is_current`]" />

                </div>
                
                <button type="button" class="btn btn-grey" @click="deleteItem(index)">
                    {{ $t('Remove') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17.764" viewBox="0 0 15 17.764">
                        <g>
                            <path d="M8.591 17.728a2.419 2.419 0 0 0 2.419 2.317h7.38a2.419 2.419 0 0 0 2.419-2.317l.475-10.939H8.116zm7.9-8.6a.605.605 0 0 1 1.21.039l-.279 7.933a.6.6 0 0 1-.6.584.6.6 0 0 1-.6-.626zm-4.207-.581a.6.6 0 0 1 .626.587l.281 7.926a.6.6 0 0 1-.6.626.6.6 0 0 1-.6-.584l-.266-7.935a.6.6 0 0 1 .569-.62zM21.6 4.37h-4.036V2.885a.6.6 0 0 0-.6-.6h-4.523a.6.6 0 0 0-.6.6V4.37H7.8a.6.6 0 0 0 0 1.21h13.8a.6.6 0 1 0 0-1.21zm-8.549-.88h3.308v.88h-3.313z" transform="translate(-7.2 -2.28)"/>
                        </g>
                    </svg>
                </button>

            </template>

            <button @click="add" type="button" class="btn btn-transparent-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                    <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
                </svg>
                {{ $t('Add more') }}
            </button>
            <button class="btn btn-primary" type="submit">{{ $t('Save') }}</button>
        </form>
    </div>
</template>

<script>
import { cvService } from "@/services/cvService";
import CvSectionMixin from "@/mixins/CvSection";
import Datepicker from "vue3-datepicker";
import moment from "moment";
import Multiselect from '@vueform/multiselect'

export default {
    name: "EditInternship",
    components: {
        Datepicker,
        Multiselect
    },
    mixins: [ CvSectionMixin ],
    data() {
        return {
            moment: moment,
            updateService: cvService.updateInternship,
            getService: cvService.internship,
            items: [
                {
                    job_title: '',
                    employer: '',
                    industry_id: '',
                    country_id: '',
                    date_from: '',
                    date_to: '',
                    description: '',
                    is_current: 0,
                }
            ],
        }
    },
    methods: {
        getNewRecords() {
            const items = this.items.map(item => {
                return {
                    job_title: item.job_title,
                    employer: item.employer,
                    industry_id: item.industry_id,
                    country_id: item.country_id,
                    date_from: item.date_from,
                    date_to: item.date_to,
                    description: item.description,
                    is_current: item.is_current,
                }
            });

            return {
                'internships': items
            }
        },
        getSingleItem() {
            return {
                job_title: '',
                employer: '',
                industry_id: '',
                country_id: '',
                date_from: '',
                date_to: '',
                description: '',
                is_current: 0,
            }
        }
    },
    computed: {
        industries() {
            return this.$store.getters.industries;
        },
        countries() {
            return this.$store.getters.countries
        }
    }
}
</script>